import * as React from "react"
import { useStaticQuery, graphql, Link } from 'gatsby'
import { StaticImage, GatsbyImage, getImage, getSrc } from "gatsby-plugin-image"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import styled from "styled-components"
import { RichText } from 'prismic-reactjs';
import htmlSerializer from '../utils/htmlSerializer';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'
import { Date } from 'prismic-reactjs'

import LayoutNew from "../components/layout-new";
import Seo from "../components/seo"

const StyledDiv2 = styled.div`
  background-image: linear-gradient(to bottom, #679898, #f0f5f5);
`

const StyledSliderCustom = styled(Slider)`
  .slick-prev {
    left: -80px !important;
  }

  .slick-next {
    right: -80px !important;
  }

  .slick-prev, .slick-next {
    width: 80px !important;
    height: 80px !important;
  }

  .slick-prev:before, .slick-next:before {
    font-size: 80px !important;
    color: #bb0000 !important;
  }

  @media (max-width:767px) {
    .slick-prev {
      left: -45px !important;
    }

    .slick-next {
      right: -45px !important;
    }

    .slick-prev, .slick-next {
      width: 40px !important;
      height: 40px !important;
    }

    .slick-prev:before, .slick-next:before {
      font-size: 40px !important;
      color: #bb0000 !important;
    }
    
    .slider-video .slick-list {
      padding: 0px 60px !important;
    }
  }
`

const h2Style = "text-site-gray-dark text-xl md:text-3xl font-extrabold text-center mt-6 md:line-clamp-3"

const PageEvents = () => {
  const data = useStaticQuery(graphql`
    query PageEventsQueryNew { 
      prismicEventsPage {
        _previewable
        data {
          page_title
          page_short_description
          page_hero_background {
            alt
            gatsbyImageData(
              width: 1920
            )
          }
          events_list_section_title
          events_list_section_description {
            richText
          }
          events {
            event_image {
              alt
              gatsbyImageData(
                width: 400
              )
            }
            event_title
            first_event_date
            second_event_date
            event_location
            event_button_name
            event_link {
              uid
              link_type
              url
            }
          }
          banner_title
          banner_button_link {
            url
            link_type
            uid 
          }
          on_demand_videos_section_title
          on_demand_videos {
            video_thumbnail {
              alt
              gatsbyImageData(
                width: 800
              )
            }
            video_title
            video_link_url
          }
        }
      }  
      photoPlaceholder: file(
        relativePath: { eq: "sections/sra-event-placeholder.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 400)
        }
      }
      webinarPlaceholder: file(
        relativePath: { eq: "events/on-demand-video.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 800)
        }
      }   
      imgHero: file(relativePath: { eq: "backgrounds/events-hero-bg-2.jpeg" }) {
        childImageSharp {
          gatsbyImageData(
            width: 1920
          )
        }
      }
    }
  `)

  const doc = data.prismicEventsPage
  const sortedEvents = doc.data.events.sort((a, b) => new Date(a.first_event_date) - new Date(b.first_event_date))
  const imgHero = getImage(doc.data.page_hero_background ? doc.data.page_hero_background : data.imgHero)
  const imgSrc = getSrc(doc.data.page_hero_background ? doc.data.page_hero_background : data.imgHero)
  const photoPlaceholder = getImage(data.photoPlaceholder)
  const webinarPlaceholder = getImage(data.webinarPlaceholder)

  const sliderSettings = {
    dots: true,
    infinite: true,
    lazyLoad: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false,
        },
      },
    ],
  }

  const sliderSettingsVideos = {
    className: "center",
    dots: true,
    infinite: true,
    lazyLoad: true,
    centerMode: true,
    centerPadding: "200px",
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: false,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          centerMode: false,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: false,
          dots: false,
        },
      },
    ],
  }

  return (
    <LayoutNew>
      <Seo
        title={doc.data.page_title}
        description={doc.data.page_short_description}
        image={imgSrc}
      />

      <div className="relative pt-14 xl:pt-20" style={{ display: "grid", background: "#000000", minHeight: "400px" }}>
        <GatsbyImage
          style={{
            gridArea: "1/1",
            objectFit: "cover",
            opacity: "0.7",
            height: 400,
          }}
          alt="hero image"
          image={imgHero}
          formats={["auto", "webp", "avif"]}
        />

        <div
          style={{
            gridArea: "1/1",
            position: "relative",
            placeItems: "center",
            display: "grid",
          }}
        >
          <div className="w-11/12 lg:w-10/12 max-w-screen-lg flex flex-col items-center justify-center px-2">
            <div className="flex flex-col justify-center">
              <h2 className="text-white text-4xl md:text-6xl font-extrabold text-center mt-4">
                {doc.data.page_title}
              </h2>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-white pt-16">
        <div className="relative w-11/12 lg:w-10/12 max-w-screen-xl flex flex-col items-center justify-center mx-auto">          
          <div className="events-list-content max-w-5xl z-10">            
            <h2 className="text-site-red text-3xl md:text-4xl uppercase font-bold text-center mb-6">{doc.data.events_list_section_title}</h2>

            <RichText
              render={doc.data.events_list_section_description.richText}
              htmlSerializer={htmlSerializer}
            />
          </div>
        
          <div className="w-9/12 xl:w-full max-w-6xl mt-12 mb-24 mx-auto z-10">
            {sortedEvents.length === 0 ? (
              <div className="grid grid-cols-1">
                <div className="flex flex-col items-center justify-center md:mx-4">
                  <GatsbyImage image={photoPlaceholder} alt="event image" />
                  <h2 className={h2Style}>New Event Coming Soon</h2>
                  <p className="text-site-gray-dark text-xl md:text-2xl text-center mt-3">
                      Event Date Soon
                    </p>

                    <p className="text-site-gray-dark text-xl md:text-3xl text-center mt-2">Event Location Soon</p>

                    <Link to="/contact">
                      <button className="w-full md:w-auto bg-site-red hover:bg-site-red-hover font-display font-semibold text-xl lg:text-3xl text-white uppercase tracking-wider px-7 pt-2 pb-3 mt-8">
                        Contact Us
                      </button>
                    </Link>
                </div>
              </div>
            ) : sortedEvents.length < 2 ? (
              <div className="grid grid-cols-1">
                {sortedEvents.map((event) => {
                  const imgData = getImage(event.event_image);
                  return (
                    <div key={event.event_title}>
                      <div className="flex flex-col items-center justify-center md:mx-4">
                        <GatsbyImage
                          image={imgData ? imgData : photoPlaceholder}
                          alt={event.event_image.alt ? event.event_image.alt : "event image"}
                        />
                        <h2 className={h2Style}>{event.event_title}</h2>
                        {event.first_event_date && !event.second_event_date ? (
                          <p className="text-site-gray-dark text-xl md:text-2xl text-center mt-3">
                            {event.first_event_date}
                          </p>
                        ) : event.first_event_date && event.second_event_date ? (
                          <p className="text-site-gray-dark text-xl md:text-2xl text-center mt-3">
                            {event.first_event_date} to {event.second_event_date}
                          </p>
                        ) : null}
                        <p className="text-site-gray-dark text-xl md:text-3xl text-center mt-2">
                          {event.event_location}
                        </p>
                        {event.event_link.link_type === "Document" || event.event_link.link_type === "Any" ? (
                          <Link to={event.event_link.uid ? `/${event.event_link.uid}` : "/"}>
                            <button className="w-full md:w-auto bg-site-red hover:bg-site-red-hover font-display font-semibold text-xl lg:text-3xl text-white uppercase tracking-wider px-7 pt-2 pb-3 mt-8">
                              {event.event_button_name}
                            </button>
                          </Link>
                        ) : event.event_link.link_type === "Web" || event.event_link.link_type === "Media" ? (
                          <a href={event.event_link.url} target="_blank" rel="noopener noreferrer">
                            <button className="w-full md:w-auto bg-site-red hover:bg-site-red-hover font-display font-semibold text-xl lg:text-3xl text-white uppercase tracking-wider px-7 pt-2 pb-3 mt-8">
                              {event.event_button_name}
                            </button>
                          </a>
                        ) : null}
                      </div>
                    </div>
                  );
                })}
              </div>
            ) : sortedEvents.length < 3 ? (
              <div className="grid grid-cols-1 xl:grid-cols-2 gap-16 xl:gap-0">
                {sortedEvents.map((event) => {
                  const imgData = getImage(event.event_image);
                  return (
                    <div key={event.event_title}>
                      <div className="flex flex-col items-center justify-center md:mx-4">
                        <GatsbyImage
                          image={imgData ? imgData : photoPlaceholder}
                          alt={event.event_image.alt ? event.event_image.alt : "event image"}
                          objectFit="contain"
                          className="w-full h-42 md:h-72"
                        />
                        <h2 className={h2Style}>{event.event_title}</h2>
                        {event.first_event_date && !event.second_event_date ? (
                          <p className="text-site-gray-dark text-xl md:text-2xl text-center mt-3">
                            {event.first_event_date}
                          </p>
                        ) : event.first_event_date && event.second_event_date ? (
                          <p className="text-site-gray-dark text-xl md:text-2xl text-center mt-3">
                            {event.first_event_date} to {event.second_event_date}
                          </p>
                        ) : null}
                        <p className="text-site-gray-dark text-xl md:text-3xl text-center mt-2">
                          {event.event_location}
                        </p>
                      </div>
                    </div>
                  );
                })}
              </div>
            ) : sortedEvents.length < 4 ? (
              <div className="grid grid-cols-1 xl:grid-cols-3 gap-16 xl:gap-0">
                {sortedEvents.map((event) => {
                  const imgData = getImage(event.event_image);
                  return (
                    <div key={event.event_title}>
                      <div className="flex flex-col items-center justify-center md:mx-4">
                        <GatsbyImage
                          image={imgData ? imgData : photoPlaceholder}
                          alt={event.event_image.alt ? event.event_image.alt : "event image"}
                          objectFit="contain"
                          className="w-full h-48"
                        />
                        <h2 className={h2Style}>{event.event_title}</h2>
                        {event.first_event_date && !event.second_event_date ? (
                          <p className="text-site-gray-dark text-xl md:text-2xl text-center mt-3">
                            {event.first_event_date}
                          </p>
                        ) : event.first_event_date && event.second_event_date ? (
                          <p className="text-site-gray-dark text-xl md:text-2xl text-center mt-3">
                            {event.first_event_date} to {event.second_event_date}
                          </p>
                        ) : null}
                        <p className="text-site-gray-dark text-xl md:text-3xl text-center mt-2">
                          {event.event_location}
                        </p>
                      </div>
                    </div>
                  );
                })}
              </div>
            ) : (
              <StyledSliderCustom {...sliderSettings}>
                {sortedEvents.map((event) => {
                  const imgData = getImage(event.event_image);
                  return (
                    <div key={event.event_title}>
                      <div className="relative flex flex-col items-center justify-center md:mx-4">
                        <GatsbyImage
                          image={imgData ? imgData : photoPlaceholder}
                          alt={event.event_image.alt ? event.event_image.alt : "event image"}
                          objectFit="contain"
                          className="w-full h-48"
                        />
                        <h2 className={h2Style}>{event.event_title}</h2>
                        {event.first_event_date && !event.second_event_date ? (
                          <p className="text-site-gray-dark text-xl md:text-2xl text-center mt-3">
                            {event.first_event_date}
                          </p>
                        ) : event.first_event_date && event.second_event_date ? (
                          <p className="text-site-gray-dark text-xl md:text-2xl text-center mt-3">
                            {event.first_event_date} to {event.second_event_date}
                          </p>
                        ) : null}
                        <p className="text-site-gray-dark text-xl md:text-3xl text-center mt-2">
                          {event.event_location}
                        </p>
                      </div>
                    </div>
                  );
                })}
              </StyledSliderCustom>
            )}
          </div>

          <div className="absolute bottom-0 opacity-10 z-0">
            <StaticImage
              src="../images/backgrounds/sra-icon-red.png"
              width={1090}
              quality={95}
              formats={["AUTO", "WEBP", "AVIF"]}
              alt="section background image"
            />
          </div>        
        </div>
      </div>

      <div className="relative" style={{ display: "grid", background: "#000000", minHeight: "200px" }}>
        <GatsbyImage
          style={{
            gridArea: "1/1",
            objectFit: "cover",
            opacity: "0.7",
            height: "200px",
          }}
          alt="hero image"
          image={imgHero}
          formats={["auto", "webp", "avif"]}
        />

        <div
          style={{
            gridArea: "1/1",
            position: "relative",
            placeItems: "center",
            display: "grid",
          }}
        >
          <div className="w-11/12 xl:w-10/12 max-w-screen-xl flex flex-col xl:flex-row items-center justify-center px-2">
            <div className="w-full xl:w-7/12 flex flex-col justify-center mb-6 xl:mb-0">
              <h2 className="text-white text-3xl md:text-4xl font-bold text-center uppercase">
                {doc.data.banner_title}
              </h2>
            </div>

            <div className="relative w-full md:w-5/12 lg:w-4/12 xl:w-5/12 flex flex-col items-center xl:items-end justify-center xl:justify-end">
              <div className="hidden xl:block">
                {doc.data.banner_button_link.link_type === "Document" || doc.data.banner_button_link.link_type === "Any"
                  ?
                    <Link to={doc.data.banner_button_link.uid ? `/${doc.data.banner_button_link.uid}` : "/"}>
                      <StaticImage
                        src="../images/sections/learn-more-button-4.png"
                        width={300}
                        quality={95}
                        formats={["AUTO", "WEBP", "AVIF"]}
                        alt="button"
                        className="transition transform hover:translate-x-2 motion-reduce:transition-none motion-reduce:hover:transform-none"
                      />
                    </Link>
                  :
                  doc.data.banner_button_link.link_type === "Web" || doc.data.banner_button_link.link_type === "Media"
                  ?
                    <a
                      href={doc.data.banner_button_link.url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <StaticImage
                        src="../images/sections/learn-more-button-4.png"
                        width={300}
                        quality={95}
                        formats={["AUTO", "WEBP", "AVIF"]}
                        alt="button"
                        className="transition transform hover:translate-x-2 motion-reduce:transition-none motion-reduce:hover:transform-none"
                      />
                    </a>
                  :
                  null
                }
              </div>

              <div className="block xl:hidden">
                <Link to={doc.data.banner_button_link ? `/${doc.data.banner_button_link.uid}` : "/"}>
                  <StaticImage
                    src="../images/sections/learn-more-button-4.png"
                    width={200}
                    quality={95}
                    formats={["AUTO", "WEBP", "AVIF"]}
                    alt="button"
                    className="motion-safe:animate-pulse animate-pulse"
                  />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <StyledDiv2 className="py-16">
        <div className="relative w-11/12 lg:w-10/12 max-w-screen-xl flex flex-col items-center justify-center mx-auto">          
          <div className="max-w-5xl">            
            <h2 className="text-white text-3xl md:text-4xl font-bold text-center uppercase">{doc.data.on_demand_videos_section_title}</h2>
          </div>
        
          <div className="w-9/12 xl:w-full max-w-6xl mt-12 mb-10 mx-auto z-10">
            {doc.data.on_demand_videos.length < 2
              ?
              <div className="grid grid-cols-1">
                {doc.data.on_demand_videos.map((onDemand) => {
                  const imgData = getImage(onDemand.video_thumbnail)
                  return (
                    <div>
                      <div className="flex flex-col items-center justify-center md:mx-4">
                        <GatsbyImage
                          image={imgData ? imgData : webinarPlaceholder}
                          alt={imgData.alt ? imgData.alt : "webinar image"}
                        />

                        <h2 className={h2Style}>{onDemand.video_title}</h2>

                        <a
                          href={onDemand.video_link_url}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <button className="w-full md:w-auto bg-site-red hover:bg-site-red-hover font-display font-semibold text-xl lg:text-2xl text-white uppercase tracking-wider px-4 pt-2 pb-3 mt-8">
                            Watch On-Demand
                          </button>
                        </a>
                      </div>
                    </div>
                  )
                })}
              </div>
              : doc.data.on_demand_videos.length < 3
                ?
                <div className="grid grid-cols-1 xl:grid-cols-2 gap-16 xl:gap-0">
                {doc.data.on_demand_videos.map((onDemand) => {
                  const imgData = getImage(onDemand.video_thumbnail)
                  return (
                    <div>
                      <div className="flex flex-col items-center justify-center md:mx-4">
                        <GatsbyImage
                          image={imgData ? imgData : webinarPlaceholder}
                          alt={imgData.alt ? imgData.alt : "webinar image"}
                          objectFit="contain"
                          className="w-full h-42 md:h-72"
                        />

                        <h2 className={h2Style}>{onDemand.video_title}</h2>

                        <a
                          href={onDemand.video_link_url}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <button className="w-full md:w-auto bg-site-red hover:bg-site-red-hover font-display font-semibold text-xl lg:text-2xl text-white uppercase tracking-wider px-4 pt-2 pb-3 mt-8">
                            Watch On-Demand
                          </button>
                        </a>
                      </div>
                    </div>
                  )
                })}
              </div>
              : doc.data.on_demand_videos.length < 4
                ?
                <div className="grid grid-cols-1 xl:grid-cols-3 gap-16 xl:gap-0">
                {doc.data.on_demand_videos.map((onDemand) => {
                  const imgData = getImage(onDemand.video_thumbnail)
                  return (
                    <div>
                      <div className="flex flex-col items-center justify-center md:mx-4">
                        <GatsbyImage
                          image={imgData ? imgData : webinarPlaceholder}
                          alt={imgData.alt ? imgData.alt : "webinar image"}
                          objectFit="contain"
                          className="w-full h-48"
                        />

                        <h2 className={h2Style}>{onDemand.video_title}</h2>

                        <a
                          href={onDemand.video_link_url}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <button className="w-full md:w-auto bg-site-red hover:bg-site-red-hover font-display font-semibold text-xl lg:text-2xl text-white uppercase tracking-wider px-4 pt-2 pb-3 mt-8">
                            Watch On-Demand
                          </button>
                        </a>
                      </div>
                    </div>
                  )
                })}
              </div>
              : doc.data.on_demand_videos.length > 3
                ?
                <StyledSliderCustom  {...sliderSettingsVideos}>
                  {doc.data.on_demand_videos.map((onDemand) => {
                  const imgData = getImage(onDemand.video_thumbnail)
                  return (
                    <div>
                      <div className="flex flex-col items-center justify-center">
                        <GatsbyImage
                          image={imgData ? imgData : webinarPlaceholder}
                          alt={imgData.alt ? imgData.alt : "webinar image"}
                          objectFit="contain"
                          className="w-full h-48"
                        />

                        <h2 className={h2Style}>{onDemand.video_title}</h2>
                        
                        <a
                          href={onDemand.video_link_url}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <button className="w-full md:w-auto bg-site-red hover:bg-site-red-hover font-display font-semibold text-xl lg:text-2xl text-white uppercase tracking-wider px-4 pt-2 pb-3 mt-8">
                            Watch On-Demand
                          </button>
                        </a>
                      </div>
                    </div>
                  )
                })}
                </StyledSliderCustom>
              :
              <div className="grid grid-cols-1">                
                <div>
                  <div className="flex flex-col items-center justify-center md:mx-4">
                    <GatsbyImage
                      image={webinarPlaceholder}
                      alt="webinar image"
                    />
                    
                    <h2 className={h2Style}>New Event On-Demand Coming Soon</h2>                    

                    <Link to="/contact">
                      <button className="w-full md:w-auto bg-site-red hover:bg-site-red-hover font-display font-semibold text-xl lg:text-3xl text-white uppercase tracking-wider px-7 pt-2 pb-3 mt-8">
                        Contact Us
                      </button>
                    </Link>                    
                  </div>
                </div>
              </div>
            }
          </div>    
        </div>
      </StyledDiv2>
    </LayoutNew>
  )
}

export default withPrismicPreview(PageEvents)
